import React, { useEffect, useState } from "react"

import ScrollToTopImg from "../images/scrolltotop.svg"

import "./layout.css"

const ScrollToTop = props => {
  const [showScroll, setShowScroll] = useState(false)
  const isBrowser = () => typeof window !== "undefined"

  useEffect(() => {
    isBrowser() && window.scrollTo(0, 0)
  }, [])

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    isBrowser() && window.scrollTo({ top: 0, behavior: "smooth" })
  }

  isBrowser() && window.addEventListener("scroll", checkScrollTop)

  return (
    <div className="scroll-wrapper">
      <img
        className="scrollTop"
        src={ScrollToTopImg}
        alt="ScrollToTop"
        onClick={scrollTop}
        style={{
          height: 55,
          background: "#fff",
          display: showScroll ? "flex" : "none",
        }}
      />
    </div>
  )
}

export default ScrollToTop
