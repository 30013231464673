import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TafheemBanner from "../images/tafheem-banner.png"
import Seamoss from "../images/seamoss.png"
// import ReconBannerIpad from "../images/recon-banner-ipad.png"
import ReconBannerMini from "../images/recon-banner-macmini.png"
import ReconBannerXl from "../images/transaction.gif"
import PrimaryColorImg from "../images/primary-color.png"
import NeutralColorImg from "../images/neutral-color.png"
import SupportingColorImg from "../images/supporting-color.png"
import TypographyImg from "../images/typography.png"
import IconsImg from "../images/icons.png"
import ComponentsImg from "../images/components.png"
import ButtonsImg from "../images/buttons.png"

import "../components/layout.css"
import Banner from "../components/banner"
import Tag from "../components/tag"
import ScrollToTop from "../components/scrollToTop"

const recon_design_system = () => (
  <Layout>
    <SEO title="Recon Design System" />
    <div>
      <div className="top-banner background-blue d-flex align-items-center justify-content-center flex-column py-8">
        <img
          className="w-sm-100 w-40"
          src={ReconBannerMini}
          alt={"ReconBanner"}
        />
      </div>
      <div className="container-md">
        <div className="d-flex justify-content-evenly flex-sm-col py-8 px-sm-2">
          <div className="pr-3 flex-basis-7">
            <div className="portfolio-detail-intro-head pb-2">
              OVERVIEW & BACKGROUND
            </div>
            <div className="portfolio-detail-intro-p">
              As we started to make a <b className="f-f-p-b">B2B application</b>{" "}
              for <b className="f-f-p-b"> Transaction Reconciliation </b>{" "}
              <span className="font-weight-bold">
                <span role="img">💳</span>
              </span>{" "}
              and some other products related to same domain, we felt the need
              to create and maintain a consistent style and visual language for
              all the products which lie under the same client.
            </div>
            <div className="portfolio-detail-intro-p mt-2">
              The Initial goal was to make a group of reusable components, led
              by clear standards which can be put together to rapidly build many
              consistent experiences.
            </div>
          </div>
          <div className="flex-basis-3 mt-sm-4">
            <div className="portfolio-detail-intro-head pb-2">ROLE & TIME</div>
            <div className="portfolio-detail-intro-head pb-1 border-bottom color-dark-blue">
              Product Designer | Avanza Solutions
            </div>
            <div className="portfolio-detail-intro-p border-bottom pb-1 pt-1">
              Information Architecture, Design System, Style Guide
            </div>
            <div className="portfolio-detail-intro-p pb-1 pt-1">2021</div>
          </div>
        </div>
      </div>
      <div className="top-banner banner-dark d-flex align-items-center py-8 px-sm-2">
        <div className="container-md">
          <div className="portfolio-detail-banner-head pb-2">
            {" "}
            <span role="img">🤔</span> Problem
          </div>
          <div className="portfolio-detail-intro-p pb-1 pt-1 ml-1">
            After looking at a series of interconnected Fintech
            <span role="img">💳</span> products for a single client it was clear
            that they needed a proper visual language to make each of the
            products consistent, intuitive & trustworthy.
          </div>
          <div className="portfolio-detail-banner-head pt-2 pb-2">
            {" "}
            <span role="img">🎯</span> Business Goal
          </div>
          <div className="portfolio-detail-intro-p pb-1 pt-1 ml-1">
            <ul>
              <li>One time cost for branding & design</li>
              <li>
                Design handoff for developers for rapid & pixel perfect
                development
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-md d-flex flex-column align-items-center py-5 px-sm-2">
        <ol className="m-0">
          <div className="portfolio-detail-head pb-2">
            <span role="img">💡</span> Solution
          </div>
          <div className="ml-2">
            <li className="portfolio-detail-intro-head">Design System</li>
            <div className="portfolio-detail-intro-p mt-1">
              The main solution was to come up with the pattern library or a
              design system that was led by principles and best practices.
              Furthermore, document each decision and component to make sure the
              visual consistency and efficiency of work.
            </div>
          </div>
          <div className="ml-2 mt-2">
            <li className="portfolio-detail-intro-head">
              Intuitive & Easy Navigation
            </li>
            <div className="portfolio-detail-intro-p mt-1">
              The other goal was to think of an intuitive and easy navigation
              across the application so that user can easily be able to find the
              right result for an action.
            </div>
          </div>
        </ol>
      </div>
      <div className="container-md d-flex flex-column align-items-center py-5 px-sm-2">
        <ol className="m-0">
          <div className="portfolio-detail-head pb-2">
            <span role="img">💪</span> Challenges
          </div>
          <div className="ml-2">
            <li className="portfolio-detail-intro-head">
              Getting everyone on same page
            </li>
            <div className="portfolio-detail-intro-p mt-1">
              The Design System doesn't only depends upon the designer but the
              whole team. If we want to make it a success{" "}
              <span role="img">🎉</span>, we need to take every member onboard,
              specially the frontend developers to ensure the technically
              correct design.
            </div>
          </div>
          <div className="ml-2 mt-2">
            <li className="portfolio-detail-intro-head">
              Prioritizing & Organizing
            </li>
            <div className="portfolio-detail-intro-p mt-1">
              We needed to make sure that along with the Design System, we
              should also be able to work on features, it's research and
              wireframes so we prioritized and organized the tasks according to
              it.
            </div>
          </div>
          <div className="ml-2 mt-2">
            <li className="portfolio-detail-intro-head">
              Getting clients onboard
            </li>
            <div className="portfolio-detail-intro-p mt-1">
              We also needed to keep the client updated about the look and feel
              and brand identity that we were creating for the product so we get
              on the same page and do the necessary changes if required.
            </div>
          </div>
        </ol>
      </div>
      <div className="banner-light d-flex align-items-center justify-content-center flex-column py-8">
        <img src={ReconBannerXl} alt={"ReconBanner"} />
      </div>
      <p className="text-center py-3">
        Pic: Transaction Reconciliation List page made using Recon Design System
        (Light/Dark mode)
      </p>
      <div className="container-md d-flex flex-column align-items-center py-5 px-sm-2">
        <div>
          <div className="portfolio-detail-head pb-2">
            <span role="img">🧱 </span> Defining Foundation
          </div>
          <div className="ml-1">
            <div className="portfolio-detail-intro-p mt-1">
              We created the system using the principles of{" "}
              <b className="f-f-p-b">atomic design</b>
              . <br />
              <br />
              <b className="f-f-p-b">Atomic design</b> is atoms, molecules,
              organisms, templates, and pages concurrently working together to
              create effective interface design systems.
              <br />
              <br />
              The basis somewhat define our{" "}
              <b className="f-f-p-b">
                {" "}
                typography, color scheme, icons, spacing, navigation &
                information architecture{" "}
              </b>{" "}
              which were important for defining our work in a consistent
              direction.
            </div>
          </div>
          <div className="d-flex flex-sm-col mt-2">
            <div className="card-shadow flex-column mr-2 flex-basis-7 w-sm-100">
              <div className="portfolio-detail-intro-head color-dark-blue">
                What is Recon?
              </div>
              <div className="portfolio-detail-intro-p mt-2">
                <b className="f-f-p-b">Recon</b> short for{" "}
                <b className="f-f-p-b"> reconciliation </b>, is a financial term
                used in Fintech domain to describe operational, technical and
                financial control activities which provide assurances to the
                validity of payment transition lifecycle, balance sheet
                accuracy, daily cash position, among others.
                <br />
                <br />
                as most of our products are linked to the transaction and
                reconciliation, our approch is to define "Recon" through our
                design.
              </div>
            </div>
            <div className="d-flex flex-column mt-sm-2">
              <div className="card-shadow flex-column flex-basis-3">
                <div className="portfolio-detail-intro-head color-dark-blue">
                  Personality
                </div>
                <div className="portfolio-detail-intro-head pt-1">
                  Trustworthy • Simple • Serious{" "}
                </div>
                <div className="portfolio-detail-intro-p mt-2">
                  our personality is based on our mission, values & promise to
                  customers.
                </div>
              </div>
              <div className="card-shadow flex-column flex-basis-3 mt-2">
                <div className="portfolio-detail-intro-head color-dark-blue">
                  Principles
                </div>
                <div className="portfolio-detail-intro-head pt-1">
                  Consistent • Intuitive • Simple{" "}
                </div>
                <div className="portfolio-detail-intro-p mt-2">
                  our principles are based on the industry norm and customer
                  needs.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-light d-flex align-items-center justify-content-center flex-column py-8">
        <div className="card-shadow flex-column flex-basis-3 bg-white p-2">
          <div className="portfolio-detail-head">Grid</div>
          <div className="portfolio-detail-intro-p pt-1">
            We used{" "}
            <span className="f-f-p-b">12 flexible columns grid system</span> to
            space out the elements on a page with{" "}
            <span className="f-f-p-b">20pt gutter </span> between each of the
            grid.
          </div>
        </div>
        <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
          <div className="portfolio-detail-head">Colors</div>
          <div className="portfolio-detail-intro-p pt-1">
            Our primary color is composed of neutral, white and blue. We also
            tried to make sure to comply with accessibility like AA standards
            contrast ratio.
          </div>
          <div className="d-flex mt-3">
            <div>
              <div className="portfolio-detail-intro-head mb-1">Primary</div>
              <img
                className="m-0"
                src={PrimaryColorImg}
                alt={"PrimaryColorImg"}
              />
            </div>
            <div className="ml-2">
              <div className="portfolio-detail-intro-head mb-1">Neutrals</div>
              <img
                className="m-0"
                src={NeutralColorImg}
                alt={"NeutralColorImg"}
              />
            </div>
            <div className="ml-2">
              <div className="portfolio-detail-intro-head mb-1">
                Supporting/Graph
              </div>
              <img
                className="m-0"
                src={SupportingColorImg}
                alt={"SupportingColorImg"}
              />
            </div>
          </div>
        </div>
        <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
          <div className="portfolio-detail-head">Typography</div>
          <div className="portfolio-detail-intro-p pt-1">
            We used <span className="f-f-p-b"> Montserrat </span> (Google Font)
            as the base typography across the entire product. Here is a showcase
            of different ways to structure content:
          </div>
          <div className="d-flex mt-4">
            <img className="m-0" src={TypographyImg} alt={"TypographyImg"} />
          </div>
        </div>
        <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
          <div className="portfolio-detail-head">Buttons</div>
          <div className="portfolio-detail-intro-p pt-1">
            We used <span className="f-f-p-b"> Material Icons </span> (Google)
            as the base font across the entire product. Here is a showcase of
            icon sets:
          </div>
          <div className="d-flex mt-4">
            <img className="m-0" src={ButtonsImg} alt={"ButtonsImg"} />
          </div>
        </div>
        <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
          <div className="portfolio-detail-head">Icons</div>
          <div className="portfolio-detail-intro-p pt-1">
            We used <span className="f-f-p-b"> Material Icons </span> (Google)
            as the base font across the entire product. Here is a showcase of
            icon sets:
          </div>
          <div className="d-flex mt-4">
            <div>
              <div className="portfolio-detail-intro-head mb-1">
                Material Icons
              </div>
              <img className="m-0" src={IconsImg} alt={"IconsImg"} />
            </div>
          </div>
        </div>
        <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
          <div className="portfolio-detail-head">Components</div>
          <div className="portfolio-detail-intro-p pt-1">
            We created components for all the neccessay elements used in the
            app.
          </div>
          <div className="d-flex mt-4">
            <div>
              <img className="m-0" src={ComponentsImg} alt={"ComponentsImg"} />
            </div>
          </div>
        </div>
      </div>
      <div id="work" className="container px-2 bt-light">
        <h2 className="header-h2 text-center mt-3">Checkout more projects</h2>
        <div>
          <div className="banner-container mobile">
            <Banner
              img={TafheemBanner}
              alt="tafheem-banner"
              color="yellow"
              route="/tafheem_us_sunnah"
            >
              <h1>Tafheem Us Sunnah</h1>
              <p>Redesigning the Mobile App</p>
              <div className="tags-container">
                <Tag name="UI Kit" />
                <Tag name="Visual Design" />
                <Tag name="Mobile App" />
              </div>
            </Banner>
          </div>
          <div className="banner-container">
            <Banner
              alt="recon-banner"
              bannerLeftClassName="p-0 py-sm-0"
              imgClassName="s-moss h-100 m-0"
              color="yellow"
              img={Seamoss}
              route="/seamoss_ecommerce"
            >
              <h1>Sea Moss Transformation</h1>
              <p>Redesining & Improving UX & CR</p>
              <div className="tags-container">
                <Tag name="UI/UX Design" />
                <Tag name="Style Guide" />
                <Tag name="UI Kit" />
                <Tag name="E-Commerce" />
              </div>
            </Banner>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop />
  </Layout>
)

export default recon_design_system
