import React from "react"

const Banner = props => {
  const {
    img,
    alt = "img",
    color = "pink",
    bannerLeftClassName = "",
    imgClassName = "",
    className = "",
    style,
    route,
  } = props
  return (
    <a className="banner-link" href={route}>
      <div className={`banner-wrapper ${color} ${className}`} style={style}>
        <div className={`banner-left w-sm-100 ${bannerLeftClassName}`}>
          <img className={`${imgClassName}`} src={img} alt={alt} />
        </div>
        <div className="banner-right">{props.children}</div>
      </div>
    </a>
  )
}

export default Banner
